<template>
  <div>
    <OrganisationTable
      :canEdit="true"
      editRoute="SuperAdminClinicEdit"
      :canCreate="true"
      createRoute="SuperAdminClinicCreate"
      :canAssignClinic="false"
    />
    <!-- <clinicList
      :canEdit="true"
      editRoute="SuperAdminClinicEdit"
      :canCreate="true"
      createRoute="SuperAdminClinicCreate"
      :canViewDetail="true"
      viewRoute="SuperAdminClinicDetail"
      :canViewClinicTreatmentHistory="true"
      clinicTreatmentHistoryRoute="SuperAdminClinicTreatmentHistory"
    /> -->
  </div>
</template>

<script>
import OrganisationTable from "../../components/organization/organizationTable.vue";

export default {
  components: {
    OrganisationTable,
  },
  data: () => ({}),
};
</script>
